<template>
    <v-container class="content">
        <v-row style="height: 97vh" justify="center" align="center">
            <div v-if="!isActive">
                <v-img :src="require('@/assets/img/logo.png')" width="350" />
            </div>
            <div v-else class="flex-grow-1">
                <price-view v-if="type == 0" :value="value" />
            </div>
        </v-row>
    </v-container>
</template>

<script>
import PriceView from "@/components/ExternalScreenPage/View/PriceView.vue";
export default {
    components: { PriceView },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isActive: false,
            value: null,
            type: null,
            version: null,
            timeoutId: null
        };
    },
    methods: {
        displayFor(time) {
            this.isActive = true;
            this.timeoutId = setTimeout(() => {
                this.isActive = false
            }, time);
        },
        updateValue(data) {
            clearTimeout(this.timeoutId);
            if (!!data.dataJson)
                this.value = JSON.parse(data.dataJson);
            this.type = data.dataType;
            this.version = data.version;
            const now = new Date();
            const date = new Date(data.activeTo);
            if (now < date)
                this.displayFor(date - now)
            else
                this.isActive = false;
        },
        async tick() {
            try {
                let resp = await this.$axios.get(this.$enums.Endpoints.Other.Screen.Poll + `?id=${this.id}`);
                if (!resp || !resp.data) {
                    this.isActive = false
                    return;
                }
                if (this.version == resp.data.version)
                    return;
                this.updateValue(resp.data)
            } catch (e) {
                this.isActive = false
            }
        },
        // var active = await this.getActive();
        // console.log('active - ' + active)
        //     if(!active) {
        //     this.isActive = false;
        //     return;
        // }
        //     await this.updateType();
        // this.value = await this.selectValue(this.currentType)
        //     this.isActive = active;
    },
    mounted() {
        this.intervalId = setInterval(async () => {
            await this.tick()
        }, 2000);
    },
    unmounted() {
        clearInterval(this.intervalId)
    }
};
</script>

<style>
html {
  overflow-y: hidden !important;
}
</style>
